<template>
  <div style="height: 100vh">
    <iframe :src="`${accountLoginURL}`" height="100%" scrolling="auto" width="100%" frameborder="0"/>
  </div>
</template>

<script>
  import store from "@/store/store";
  import { mapMutations } from "vuex";

  export default {
    name: 'Login',
    data: () => ({
    }),
    computed: {
      accountLoginURL(){
        return `${process.env.VUE_APP_ACCOUNT_URL}${process.env.VUE_APP_ACCOUNT_LOGIN_PATH}?callback=${window.location.origin}${process.env.VUE_APP_ACCOUNT_LOGIN_CALLBACK_PATH}&userRole=${process.env.VUE_APP_ACCOUNT_USER_ROLE}&appCode=${process.env.VUE_APP_ACCOUNT_APP_CODE}&appVersion=${process.env.VUE_APP_BUILD_VERSION}`;
      }
    },
    methods: {
      ...mapMutations(["showmsg"]),
    },
    async beforeRouteEnter(to, from, next) {
      const logged = await store.dispatch('checkAuthentication');
      if (logged) {
        next('/');
      } else {
        next();
      }
    }
  };
</script>

<style scoped>
iframe{
  overflow:hidden !important;
  border: none;
}
</style>
